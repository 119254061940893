import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import kebabCase from "lodash/kebabCase";
import { toast } from "react-toastify";
import modalStyles from "../../../components/ModalWrapper/ModalSlideShowWrapper.module.scss";
import createReportAdminRenderPageStyles from "./CreateReportAdminRenderPage.module.scss";
import { Container, Row, Col, Form, Button, InputGroup, ToggleButtonGroup, ToggleButton, Badge, Alert } from "react-bootstrap";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { useTranslation } from "react-i18next";
import { ReportPartIcon } from "../../../components/RemixIcons";
import ReactLoading from "react-loading";
import moment from "moment-timezone";
import { ReportPart } from "../../../@types/report-api";
import { getRelevantTranslationFor } from "../../../tools/multiLingualTools";
import Card from "../../../components/Card/Card";
import { MultiLingual } from "../../../@types/sphere-api";
import api from "../../../api/Api";
import { captureException } from "@sentry/react";
import { getErrorTranslation } from "../../../tools/errorTools";
import GlobalStateContext from "../../../context/globalState/GlobalStateContext";

const styles = { ...modalStyles, ...createReportAdminRenderPageStyles };

const dicoPrefix = "pages.report.admin.renders";

type Props = {
  currentPageIdx?: number;
  onSubmit?: (state?: any) => void;
};

type FormInputs = {
  name: string;
  format: "pdf" | "docx";
  lang: string;
  partsIds: string[];
};

const defaultName = (reportName: MultiLingual) =>
    `${moment().format("DD-MM-YYYY")}_${kebabCase(getRelevantTranslationFor(reportName))}`;

const selectablePart = (part: ReportPart) => part.status !== 'inProgress';

const CreateReportAdminRenderPage: React.FC<Props> = ({ onSubmit = () => {} }) => {
  const { t } = useTranslation("i18n");
  const { state: { context: { reportCtx } } } = useContext(GlobalStateContext);
  const [saving, setSaving] = useState<false | "save" | "disable">(false);

  const { register, handleSubmit, errors, formState, control, setValue, reset } =
    useForm<FormInputs>({
      defaultValues: {
        name: "",
        format: "pdf",
        partsIds: [],
      },
    });

  useEffect(() => {
    if (reportCtx) {
      reset({
        name: defaultName(reportCtx.name),
        format: "pdf",
        partsIds: reportCtx.parts.filter(selectablePart).map((part) => part.id),
      });
    }
  }, [reportCtx, reset]);

  const allPartsIds = useMemo(() => reportCtx?.parts.filter(selectablePart).map(p => p.id) || [], [reportCtx]);

  const onSubmitForm = async (form: FormInputs, event: any) => {
    if (!reportCtx?.id) {
      return;
    }

    setSaving("save");
    try {
      await api.renderReport(reportCtx.id, form);
      toast.success(t(`${dicoPrefix}.create.success`, {}));
      onSubmit();
    } catch (error) {
      captureException(error);

      toast.error(
        getErrorTranslation({
          t,
          error,
          defaultKey: `${dicoPrefix}.create.error`,
        })
      );
    }
    setSaving(false);
  };

  return !reportCtx ? <></> : (
    <div className={styles.page}>
      <PageTitle
        title={t(`${dicoPrefix}.create.title`)}
      />

      <div className={styles.content}>
        <Container>
          <Form
            onSubmit={handleSubmit(onSubmitForm)}
            autoComplete='off'
            noValidate
          >
            <Row className="justify-content-md-center">
              <Col sm={8}>
                <Form.Group controlId='name'>
                  <Form.Label>
                    {t(`${dicoPrefix}.labels.name`)} *
                  </Form.Label>

                  <InputGroup>
                    <Form.Control
                      name='name'
                      type='text'
                      autoComplete='off'
                      ref={register({
                        required: `${t(`common.validations.required`)}`,
                      })}
                      isInvalid={formState.isSubmitted && !!errors.name}
                    />

                    <Controller
                      name='format'
                      control={control}
                      render={(field) => (
                        <ToggleButtonGroup
                          className={styles.toggleFormat}
                          {...field}
                        >
                          <ToggleButton value='pdf'>
                            {t(`${dicoPrefix}.labels.format.pdf`)}
                          </ToggleButton>
                          <ToggleButton value='docx'>
                            {t(`${dicoPrefix}.labels.format.docx`)}
                          </ToggleButton>
                        </ToggleButtonGroup>
                      )}
                    />
                  </InputGroup>

                  {errors.name && (
                    <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
                      {errors.name.message}
                    </Form.Control.Feedback>
                  )}
                </Form.Group>
              </Col>
            </Row>

            <Row className="justify-content-md-center">
              <Col sm={8}>
                <Form.Group controlId='lang'>
                  <Form.Label>
                    {t(`${dicoPrefix}.labels.lang`)}
                  </Form.Label>

                  <Form.Control
                    name='lang'
                    as='select'
                    ref={register}
                  >
                    {reportCtx.languages.map((lang) => (
                      <option key={lang} value={lang}>
                        {t(`pages.user.localeValues.${lang}`)}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>

            <Row className='justify-content-md-center'>
              <Col sm={8}>
                <Form.Group>
                  <Form.Label>
                    {t(`${dicoPrefix}.labels.parts`)} *
                  </Form.Label>

                  <Controller
                    name='partsIds'
                    control={control}
                    rules={{
                      validate: (value) =>
                        value.length > 0 || `${t(`common.validations.selectMinOneItem`)}`,
                    }}
                    render={(field) => (
                      <>
                        {reportCtx?.parts.map((reportPart, index) => (
                          <Card
                            key={index}
                            title={
                              <div className={styles.cardTitleContainer}>
                                <div className={styles.cardTitle}>
                                  {getRelevantTranslationFor(reportPart.name) || ''}
                                </div>

                                {reportPart.status !== 'empty' && (
                                  <Badge
                                    className={styles.cardStatus}
                                    variant={
                                      reportPart.status === 'draft'
                                        ? 'primary'
                                        : reportPart.status === 'inProgress'
                                        ? 'secondary'
                                        : reportPart.status === 'validated'
                                        ? 'success'
                                        : reportPart.status === 'preview'
                                        ? 'warning'
                                        : 'dark'
                                    }
                                  >
                                    {t(`pages.report.status.${reportPart.status}`)}
                                  </Badge>
                                )}
                              </div>
                            }
                            variant={field.value.includes(reportPart.id) ? 'secondary' : 'primary'}
                            className={`${styles.cardContainer} ${styles[reportPart.status]}`}
                            headerClassName={styles.cardHeader}
                            bodyClassName={styles.cardBody}
                            icon={<ReportPartIcon />}
                            onClick={() => {
                              if (selectablePart(reportPart)) {
                                const newPartsIds = field.value.includes(reportPart.id)
                                  ? field.value.filter((id: string) => id !== reportPart.id)
                                  : [...field.value, reportPart.id];

                                setValue("partsIds", newPartsIds);
                              }
                            }}
                          >
                            {reportPart.status === 'inProgress' && (
                              <Alert variant='info'>
                                {t(`${dicoPrefix}.message.${reportPart.status}`)}
                              </Alert>
                            )}
                            {reportPart.status === 'preview' && (
                              <Alert variant='warning'>
                                {t(`${dicoPrefix}.message.${reportPart.status}`)}
                              </Alert>
                            )}
                          </Card>
                        ))}

                        <div className={styles.partsActionAndValidation}>
                          <div>
                            {errors.partsIds && (
                              <Form.Control.Feedback type='invalid' style={{ display: 'block' }}>
                                {(errors.partsIds as any).message}
                              </Form.Control.Feedback>
                            )}
                          </div>
                          <Button variant='link' onClick={() => {;
                            setValue('partsIds', field.value.length ? [] : allPartsIds);
                          }}>
                            {field.value.length ? t(`common.action.unselectAll`) : t(`common.action.selectAll`)}
                          </Button>
                        </div>
                      </>
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} className={styles.buttons}>
                <Button
                  variant='secondary'
                  onClick={() => onSubmit()}
                >
                  {t('common.action.close')}
                </Button>

                <Button
                  disabled={!!saving}
                  variant='primary'
                  name='submit'
                  type='submit'
                >
                  {saving === 'save' && (
                    <div className={styles.submitContent}>
                      <ReactLoading
                        type={"bars"}
                        color={"#DDD"}
                        height={"30px"}
                        width={"40px"}
                      />
                      <div className={styles.submitText}>
                        {t("common.action.saving")}
                      </div>
                    </div>
                  )}
                  {!saving && t("common.action.validate")}
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
      </div>
    </div>
  );
};

export default CreateReportAdminRenderPage;
