import { API, Auth } from "aws-amplify";
import {
  Myself,
  GeoPoint,
  Profile,
  Email,
  feelingCategory,
  FeelingEvaluationPeriod,
  FeelingGeneralReportResponse,
  ThinkingGeneralReportResponse,
  FeelingType,
  Module,
} from "../@types/webapp-api";
import {
  FacetResponse,
  Feeling,
  FeelingGoal,
  FeelingWord,
  FilterDate,
  Note,
  PaginableList,
  PostBulkMembers,
  PostMember,
  PostTag,
} from "../@types/seen-apps";
import {
  Thinking,
  Axis,
  ThinkingCreateRequest,
  SphereAxeResponse,
  SphereAxe,
} from "../@types/thinking-api";
import {
  ReportGroup,
  Sphere,
  SphereReportGroupFromDB,
  SphereSubscriptionReport,
  SphereTagFacetResponse,
  SphereTagSelectionPut,
  SphereTagSelectionResponse,
  Tag,
} from "../@types/sphere-api";
import { v4 as uuidv4 } from "uuid";
import { Session, SessionPost, SessionPut } from "../@types/session-api";
import {
  MemberResponse,
  SessionMemberPost,
  SessionMemberPut,
  Subject,
  Subscription,
} from "../@types/member-api";

import moment from "moment-timezone";
import {
  Form,
  FormEntitlementBody,
  FormEntitlementResponse,
  FormReportResponse,
  LightForm,
  FormSurvey,
  FormSurveyPost,
  FormSurveyPut,
  FormReportDefinition,
} from "../@types/form-api";
import {
  UrlAlias,
  UrlCampaignResponse,
  UrlCreationInput,
  UrlUpdateInput,
} from "../@types/shorten-url-api";
import {
  Strategy,
  StrategyAssessment,
  StrategyAssessmentPost,
  StrategyAssessmentPut,
  StrategyPost,
  StrategyPut,
} from "../@types/strategy-api";
import {
  GeneralReportRequest,
  Interval,
  ReportResponse,
} from "../@types/report";
import {
  TranslationRequest,
  TranslationResponse,
} from "../@types/translation-api";
import { isSuperAdminModeEnabled } from "../modes";
import { ChatResponse, FeelingContext } from "../@types/ai-api";
import {
  PromotionCode,
  PromotionCodePost,
  SubscriptionReport,
} from "../@types/user-service-api";
import {
  CampaignPost,
  CampaignPut,
  CampaignResponse,
  Cycle,
  CyclePut,
  ParticipationPut,
  ParticipationResponse,
} from "../@types/campaign-api";
import {
  ReportPost,
  ReportPut,
  ReportResponse as CustomReportResponse,
  CardResponse,
  ReportRenders,
} from "../@types/report-api";

const SOURCE = "app";

const GET = "get";
const POST = "post";
const PUT = "put";
const DELETE = "del";

export const DEFAULT_GROUP_ID = "default";

const MAX_SAFE = 10000;

const sleep = (ms = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const extractErrorFromApiResponse = (
  error: any
): { statusCode: number; data: any } => {
  const { response } = error;
  return { statusCode: error, data: response.data };
};

class Api {
  language?: string;
  errorCallback: (
    description: string,
    detail: string,
    timestamp: string
  ) => void;

  constructor() {
    this.language = undefined;
    this.errorCallback = () => {};
  }

  setLanguage(language: string) {
    this.language = language;
  }

  setErrorCallback(
    callback: (description: string, detail: string, timestamp: string) => void
  ) {
    this.errorCallback = callback;
  }

  _callRequestWithPagination = async <T>(request: {
    path: string;
    max: number;
    offset: number;
    queryString: {
      [name: string]: string | number;
    };
  }): Promise<PaginableList<T>> => {
    const {
      accessToken: { jwtToken },
    } = (await Auth.currentSession()) as any;
    try {
      const queryString = !request.queryString
        ? ""
        : Object.keys(request.queryString)
            .map((key) => `${key}=${request.queryString[key]}`)
            .join("&");
      const query = `${request.path}?_max=${request.max}&_offset=${request.offset}&${queryString}`;
      const response = await API.get("seenapps", `${query}`, {
        headers: {
          Authorization: jwtToken,
          Source: SOURCE,
          "Accept-Language": this.language,
          "Enable-Super-Admin": isSuperAdminModeEnabled() ? "true" : undefined,
        },

        response: true,
      });

      const result: PaginableList<T> = {
        items: response.data as T[],
        max: Number.parseInt(response.headers["x-pagination-max"]) || 0,
        offset: Number.parseInt(response.headers["x-pagination-offset"]) || 0,
        count: Number.parseInt(response.headers["x-pagination-count"]) || 0,
      };

      return result;
    } catch (error) {
      throw error;
    }
  };

  _callRequest = async (
    method: string,
    path: string,
    defaultValue: any | any[] = null,
    body: any | any[] = undefined
  ) => {
    const {
      accessToken: { jwtToken },
    } = (await Auth.currentSession()) as any;
    try {
      const response = await (API as any)[method]("seenapps", `${path}`, {
        headers: {
          Authorization: jwtToken,
          Source: SOURCE,
          "Accept-Language": this.language,
          "Enable-Super-Admin": isSuperAdminModeEnabled() ? "true" : undefined,
        },
        response: true,
        body,
      });

      return response && response.data ? response.data : defaultValue;
    } catch (error) {
      throw error;
    }
  };

  _callRequestWithoutToken = async (
    method: string,
    path: string,
    defaultValue: any | any[] = null,
    body: any | any[] = undefined
  ) => {
    try {
      const response = await (API as any)[method]("seenapps", `${path}`, {
        headers: {
          Source: SOURCE,
          "Accept-Language": this.language,
          "Enable-Super-Admin": isSuperAdminModeEnabled() ? "true" : undefined,
        },
        response: true,
        body,
      });

      return response && response.data ? response.data : defaultValue;
    } catch (error) {
      throw error;
    }
  };

  getMySelf = async (): Promise<Myself> => {
    const myself = await this._callRequest(GET, "/v1/me");
    return myself;
  };

  getMyselfReport = async (): Promise<FeelingGeneralReportResponse> => {
    const report = await this._callRequest(
      POST,
      `/feelings/myself-reports?_filters=timestamp>=${moment()
        .subtract(7, "days")
        .startOf("day")
        .toISOString()};timestamp<=${moment().toISOString()};`,
      {},
      {
        template: "general",
      } as GeneralReportRequest
    );

    return report;
  };

  getFeeling = async (id: string): Promise<Feeling> => {
    const feelings = (await this._callRequest(
      GET,
      `/feelings?_filters=id=${id}`
    )) as Feeling[];
    const [feeling] = feelings;
    return feeling;
  };
  getFeelings = async (
    from: Date,
    to: Date,
    filters?: {
      personalNotesSearch: string;
      colors?: string[];
      sphereIds?: string[];
      sessionIds?: string[];
      goalLevels?: number[];
      goalIds?: string[];
      goalRanking?: number;
    }
  ): Promise<Feeling[]> => {
    const filtersQueries: string[] = [];
    if (filters?.personalNotesSearch) {
      filtersQueries.push(
        encodeURIComponent(`personalNotes=${filters.personalNotesSearch}`)
      );
    }
    if (filters?.sphereIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sphereId=${filters.sphereIds.join(",")}`)
      );
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sessionId=${filters.sessionIds.join(",")}`)
      );
    }
    if (filters?.personalNotesSearch) {
      filtersQueries.push(
        encodeURIComponent(`personalNotes=${filters.personalNotesSearch}`)
      );
    }
    if (filters?.colors?.length) {
      filtersQueries.push(
        encodeURIComponent(`color=${filters.colors.join(",")}`)
      );
    }
    if (filters?.goalLevels?.length) {
      filtersQueries.push(
        encodeURIComponent(`goalLevels=${filters.goalLevels.join(",")}`)
      );
    }
    if (filters?.goalIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`goalIds=${filters.goalIds.join(",")}`)
      );
    }
    if (filters?.goalRanking) {
      filtersQueries.push(
        encodeURIComponent(`goalRankings<=${filters.goalRanking}`)
      );
    }

    const feelings = await this._callRequest(
      GET,
      `/feelings?_max=${MAX_SAFE}&_filters=timestamp>=${from.toISOString()};timestamp<=${to.toISOString()};${filtersQueries.join(
        ";"
      )}&_sort-field=timestamp&_sort-dir=desc`
    );
    return feelings;
  };

  getFeelingFacets = async (from: Date, to: Date): Promise<Feeling[]> => {
    const facets = await this._callRequest(
      GET,
      `/feelings/facets?_filters=timestamp>=${from.toISOString()};timestamp<=${to.toISOString()};`
    );
    return facets;
  };

  getAxes = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<Axis>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: "/thinkings/definitions/axes",
      queryString,
    });
  };

  getAxis = async (id: string) => {
    return this._callRequest(GET, `/thinkings/definitions/axes/${id}`);
  };

  getAxesFacets = async () => {
    return this._callRequest(GET, `/thinkings/definitions/axes/facets`);
  };

  addOrUpdateAxis = async ({
    id,
    ...axis
  }: Omit<Axis, "creationDate" | "updateDate">) => {
    const response = await this._callRequest(
      PUT,
      `/thinkings/definitions/axes/${id}`,
      null,
      axis
    );
    return response;
  };

  deleteAxis = async (axis: Axis) => {
    const response = await this._callRequest(
      DELETE,
      `/thinkings/definitions/axes/${axis.id}`
    );
    return response;
  };

  getSphereAxes = async ({
    sphereId,
    memberCategory,
  }: {
    sphereId: string;
    memberCategory: string;
  }): Promise<SphereAxeResponse[]> => {
    return await this._callRequest(
      GET,
      `/thinkings/definitions/sphere-axes/${sphereId}_${memberCategory}`,
      {}
    );
  };

  updateSphereAxes = async ({
    sphereId,
    memberCategory,
    axes,
  }: {
    sphereId: string;
    memberCategory: string;
    axes: SphereAxe[];
  }): Promise<SphereAxeResponse[]> => {
    return await this._callRequest(
      PUT,
      `/thinkings/definitions/sphere-axes/${sphereId}_${memberCategory}`,
      {},
      axes
    );
  };

  getFeelingWords = async (): Promise<FeelingWord[]> => {
    const words = await this._callRequest(GET, `/feelings/definitions/words`);
    return words;
  };

  getThinkings = async (
    from: Date,
    to: Date,
    filters?: {
      sphereIds?: string[];
      sessionIds?: string[];
    }
  ): Promise<Thinking[]> => {
    const filtersQueries: string[] = [];
    if (filters?.sphereIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sphereId=${filters.sphereIds.join(",")}`)
      );
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sessionId=${filters.sessionIds.join(",")}`)
      );
    }

    const thinkings = await this._callRequest(
      GET,
      `/thinkings?_max=${MAX_SAFE}&_filters=creationDate>=${from.toISOString()};creationDate<=${to.toISOString()};${filtersQueries.join(
        ";"
      )}&_sort-field=creationDate&_sort-dir=desc`
    );
    return thinkings;
  };

  getThinking = async (thinkingId: string): Promise<Thinking | undefined> => {
    const thinking = await this._callRequest(GET, `/thinkings/${thinkingId}`);
    return thinking;
  };

  getThinkingSmartReport = async ({
    sphereId,
    sessionId,
    category,
    linkTo,
    from,
    to,
  }: {
    sphereId?: string;
    sessionId?: string;
    from: string;
    to: string;
    linkTo: "session" | "sphere";
    category: feelingCategory | feelingCategory[];
  }): Promise<ThinkingGeneralReportResponse> => {
    const filters = [
      `timestamp<=${to}`,
      `timestamp>=${from}`,
      `linkTo=${linkTo}`,
    ];
    if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
    }
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
    }
    if (category) {
      filters.push(
        `category=${Array.isArray(category) ? category.join(",") : category}`
      );
    }
    const feelingReportTask = this._callRequest(
      POST,
      `/thinkings/sphere-reports?_filters=${filters.join(";")}`,
      {},
      {
        template: "general",
      }
    );
    return feelingReportTask;
  };
  getThinkingPublicReport = async ({
    sphereId,
    sessionId,
    category,
    from,
    to,
  }: {
    sphereId: string;
    sessionId: string;
    from: string;
    to: string;
    category: feelingCategory | feelingCategory[];
  }): Promise<ThinkingGeneralReportResponse> => {
    const filters = [
      `timestamp<=${to}`,
      `timestamp>=${from}`,
      `linkTo=session`,
    ];
    filters.push(`sphereId=${sphereId}`);
    filters.push(`sessionId=${sessionId}`);
    filters.push(
      `category=${Array.isArray(category) ? category.join(",") : category}`
    );
    const feelingReportTask = this._callRequest(
      POST,
      `/thinkings/sphere-reports?_filters=${filters.join(";")}`,
      {},
      {
        template: "public",
      }
    );
    return feelingReportTask;
  };

  saveUserProfile = async (profile: Partial<Profile>) => {
    const response = await this._callRequest(PUT, `/v1/profile`, null, profile);
    return response;
  };

  addEmail = async (email: string) => {
    const response = await this._callRequest(
      PUT,
      `/v1/emails/${email}`,
      null,
      {}
    );
    return response;
  };

  sendEmailValidation = async (email: Email) => {
    const response = await this._callRequest(
      POST,
      `/v1/emails/${email.email}/verification`,
      null,
      {}
    );
    return response;
  };
  verifyEmail = async ({
    userId,
    email,
    code,
  }: {
    userId: string;
    email: string;
    code: string;
  }) => {
    const response = await this._callRequest(
      PUT,
      `/users/${userId}/emails/${email}/verification/${code}`,
      null,
      {}
    );
    return response;
  };

  deleteEmail = async (email: Email) => {
    const response = await this._callRequest(
      DELETE,
      `/v1/emails/${email.email}`,
      null,
      {}
    );
    return response;
  };

  setPrimayEmail = async (userId: string, email: Email) => {
    const response = await this._callRequest(
      PUT,
      `/users/${userId}/emails/${email.email}/primary`,
      null,
      {}
    );
    return response;
  };

  getSphere = async (id: string) => {
    return this._callRequest(GET, `/spheres/${id}`);
  };

  getSphereMembers = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<MemberResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/spheres/${sphereId}/members`,
      queryString,
    });
  };

  getSphereTags = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<Tag>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/spheres/${sphereId}/tags`,
      queryString,
    });
  };

  getSphereTagsSelections = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<SphereTagSelectionResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/spheres/${sphereId}/tags-selections`,
      queryString,
    });
  };
  getSphereTagsSelection = async ({
    sphereId,
    selectionId,
  }: {
    sphereId: string;
    selectionId: string;
  }): Promise<SphereTagSelectionResponse> => {
    return await this._callRequest(
      GET,
      `/spheres/${sphereId}/tags-selections/${selectionId}`
    );
  };
  initializeTags = async ({
    sphereId,
  }: {
    sphereId: string;
  }): Promise<{
    tags: (Tag & { status: "created" | "ignored" })[];
    selections: (SphereTagSelectionPut & {
      status: "created" | "ignored";
    })[];
  }> => {
    return await this._callRequest(
      POST,
      `/spheres/${sphereId}/tags/initialize`,
      { selections: [], tags: [] },
      {}
    );
  };
  addOrUpdateSphereTagsSelection = async ({
    sphereId,
    selectionId,
    selection,
  }: {
    sphereId: string;
    selectionId: string;
    selection: SphereTagSelectionPut;
  }): Promise<SphereTagSelectionResponse> => {
    return await this._callRequest(
      PUT,
      `/spheres/${sphereId}/tags-selections/${selectionId}`,
      null,
      selection
    );
  };
  getSphereTagFacets = async ({
    searchQuery,
    filters,
    sphereId,
  }: {
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<SphereTagFacetResponse> => {
    let queryString: string[] = [];
    if (filters?.length) {
      queryString.push(`_filters=${filters.join(";")}`);
    }
    if (searchQuery) {
      queryString.push(`q=${searchQuery}`);
    }

    return await this._callRequest(
      GET,
      `/spheres/${sphereId}/tags/facets?${
        queryString.length ? queryString.join("&") : ""
      }`
    );
  };

  getSphereTag = async (sphereId: string, tagId: string): Promise<Tag> => {
    return await this._callRequest(GET, `/spheres/${sphereId}/tags/${tagId}`);
  };

  updateSphereTag = async (
    sphereId: string,
    tagId: string,
    tag: PostTag
  ): Promise<Tag> => {
    return await this._callRequest(
      PUT,
      `/spheres/${sphereId}/tags/${tagId}`,
      null,
      tag
    );
  };

  addSphereTag = async (sphereId: string, tag: PostTag): Promise<Tag> => {
    return await this._callRequest(
      POST,
      `/spheres/${sphereId}/tags`,
      null,
      tag
    );
  };

  getSphereReportGroups = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<SphereReportGroupFromDB>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/spheres/${sphereId}/report-groups`,
      queryString,
    });
  };

  getSphereReportGroup = async (
    sphereId: string,
    groupId: string
  ): Promise<Tag> => {
    return await this._callRequest(
      GET,
      `/spheres/${sphereId}/report-groups/${groupId}`
    );
  };

  updateSphereReportGroup = async (
    sphereId: string,
    groupId: string,
    reportGroup: ReportGroup
  ): Promise<ReportGroup> => {
    return await this._callRequest(
      PUT,
      `/spheres/${sphereId}/report-groups/${groupId}`,
      null,
      reportGroup
    );
  };

  addSphereReportGroup = async (
    sphereId: string,
    reportGroup: ReportGroup
  ): Promise<ReportGroup> => {
    return await this._callRequest(
      POST,
      `/spheres/${sphereId}/report-groups`,
      null,
      reportGroup
    );
  };

  deleteSphereReportGroup = async (
    sphereId: string,
    groupId: string
  ): Promise<ReportGroup> => {
    return await this._callRequest(
      DELETE,
      `/spheres/${sphereId}/report-groups/${groupId}`,
      null
    );
  };

  getSphereMember = async (
    sphereId: string,
    memberId: string
  ): Promise<MemberResponse> => {
    return await this._callRequest(
      GET,
      `/spheres/${sphereId}/members/${memberId}`
    );
  };

  addSphereMember = async (member: PostMember): Promise<MemberResponse[]> => {
    return await this._callRequest(POST, `/members`, null, member);
  };

  bulkSphereMembers = async (
    sphereId: string,
    bulkRequest: PostBulkMembers
  ): Promise<MemberResponse[]> => {
    return await this._callRequest(
      POST,
      `/spheres/${sphereId}/members/bulk`,
      null,
      bulkRequest
    );
  };

  updateSphereMember = async (
    sphereId: string,
    memberId: string,
    member: Partial<PostMember>
  ): Promise<MemberResponse> => {
    return await this._callRequest(
      PUT,
      `/spheres/${sphereId}/members/${memberId}`,
      null,
      member
    );
  };

  deleteSphereMember = async (
    sphereId: string,
    memberId: string
  ): Promise<MemberResponse[]> => {
    return await this._callRequest(
      DELETE,
      `/spheres/${sphereId}/members/${memberId}`,
      null
    );
  };

  getSphereCategories = async (): Promise<FeelingWord[]> => {
    const categories = await this._callRequest(
      GET,
      `/spheres/definitions/categories?_max=200`
    );
    return categories;
  };

  getSphereThinkingAxes = async (
    memberCategory?: string,
    sphereId?: string
  ): Promise<SphereAxeResponse[]> => {
    return this._callRequest(
      GET,
      `/thinkings/definitions/sphere-axes/${sphereId}_${memberCategory}`,
      []
    );
  };
  getVersion = async () => {
    try {
      const response = await this._callRequest(GET, `/version`);
      return response.version;
    } catch (error) {
      return "";
    }
  };

  addFeeling = async ({
    campaignId,
    sphereId,
    sessionId,
    value,
    energy,
    deviceCode,
    deviceName,
    category,
    geoPoint,
    validationCode,
    disableThinkingCreation,
    stepNumber,
    evaluationPeriod,
    fromDirectLink,
    specificTagIds,
  }: {
    campaignId?: string;
    sphereId?: string;
    sessionId?: string;
    value: number;
    energy: number;
    deviceCode: string;
    deviceName?: string;
    category?: feelingCategory;
    geoPoint: GeoPoint;
    validationCode?: string;
    disableThinkingCreation?: boolean;
    stepNumber?: string | number;
    evaluationPeriod?: FeelingEvaluationPeriod;
    fromDirectLink?: boolean;
    specificTagIds?: string | string[];
  }) => {
    const locale = moment.tz.guess();
    let number =
      !stepNumber || Number.isInteger(stepNumber)
        ? stepNumber
        : Number.parseInt(`${stepNumber}`, 10);

    return this._callRequest(POST, `/feelings`, null, {
      campaignId,
      sphereId,
      sessionId,
      value,
      energy,
      deviceCode,
      deviceName,
      geoPoint,
      category,
      validationCode,
      disableThinkingCreation,
      locale,
      stepNumber: number,
      evaluationPeriod,
      fromDirectLink,
      specificTagIds,
    });
  };
  updateFeeling = async (
    feelingId: string,
    {
      sphereId,
      wordId,
      category,
      personalNotes,
      goals,
    }: {
      sphereId?: string;
      category?: string;
      wordId?: string;
      personalNotes?: Note[];
      goals?: FeelingGoal[];
    }
  ) => {
    return this._callRequest(PUT, `/feelings/${feelingId}`, null, {
      sphereId,
      category,
      wordId,
      personalNotes,
      goals,
    });
  };

  updateThinking = async (
    thinkingId: string,
    axes: {
      id: string;
      value?: number;
    }[],
    geoPoint: GeoPoint
  ) => {
    return this._callRequest(PUT, `/thinkings/${thinkingId}`, null, {
      axes,
      geoPoint,
    });
  };

  _getThinking = async (
    sphereId: string,
    feeling: string,
    maxTry = 15
  ): Promise<any | undefined> => {
    const thinkingId = `${sphereId}_${feeling}_0`;

    const thinking = await this._callRequest(
      GET,
      `/thinkings/${thinkingId}`,
      undefined,
      undefined
    );
    const tries = maxTry - 1;
    if (!thinking && tries > 0) {
      await sleep();
      return await this._getThinking(sphereId, feeling, tries);
    } else if (!thinking) {
      this.errorCallback(
        `Unable to find the thinking after 15 tries : id = ${thinkingId}`,
        "",
        ""
      );
      return null;
    }
    return thinking;
  };

  /**
   * AxesValue format = {id: string, "value": number (beetween -1 and 1)}
   */
  addThinking = async (thinking: ThinkingCreateRequest) => {
    const response = await this._callRequest(
      POST,
      `/thinkings`,
      thinking,
      thinking
    );
    return response;
  };

  getSmartReport = async ({
    sphereId,
    sessionId,
    category,
    from,
    to,
    feelingType,
    include,
    interval,
  }: {
    sphereId?: string;
    sessionId?: string;
    from: string;
    to: string;
    category: feelingCategory | feelingCategory[];
    feelingType: "feeling" | FeelingEvaluationPeriod;
    include?: (
      | "words"
      | "mood"
      | "colorByTime"
      | "globalColors"
      | "participants"
    )[];
    interval?: Interval;
  }): Promise<FeelingGeneralReportResponse> => {
    const filters = [`timestamp<=${to}`, `timestamp>=${from}`];
    if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
    }
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
    }
    if (category) {
      filters.push(
        `category=${Array.isArray(category) ? category.join(",") : category}`
      );
    }
    if (feelingType === "feeling") {
      filters.push(`isAnEvaluation=false`);
    } else {
      filters.push(`isAnEvaluation=true`);
      filters.push(`evaluationPeriodType=${feelingType.type}`);
    }

    const feelingReportTask = this._callRequest(
      POST,
      `/feelings/sphere-reports?_filters=${filters.join(";")}`,
      {},
      {
        template: "general",
        generalOptions: {
          include,
          interval,
        },
      }
    );
    return feelingReportTask;
  };

  getPublicReport = async ({
    sphereId,
    sessionId,
    category,
    from,
    to,
  }: {
    sphereId: string;
    sessionId: string;
    from: string;
    to: string;
    category: feelingCategory | feelingCategory[];
  }): Promise<FeelingGeneralReportResponse> => {
    const filters = [`timestamp<=${to}`, `timestamp>=${from}`];
    filters.push(`sphereId=${sphereId}`);
    filters.push(`sessionId=${sessionId}`);
    filters.push(
      `category=${Array.isArray(category) ? category.join(",") : category}`
    );

    const feelingReportTask = this._callRequest(
      POST,
      `/feelings/sphere-reports?_filters=${filters.join(";")}`,
      {},
      {
        template: "public",
      }
    );
    return feelingReportTask;
  };
  joinSphere = async (
    sphereId: string,
    accessCode: string,
    name: string,
    email: string
  ) => {
    const member = await this._callRequest(
      POST,
      `/spheres/${sphereId}/join`,
      {},
      {
        sphereKey: accessCode,
        name,
        email,
      }
    );
    return member;
  };
  leaveSphere = async (sphereId: string, memberId: string) => {
    await this._callRequest(
      DELETE,
      `/spheres/${sphereId}/members/${memberId}`,
      {},
      {}
    );
    return true;
  };
  saveSphere = async (id: string, sphere: Partial<Sphere>) => {
    const sphereUpdated = await this._callRequest(
      PUT,
      `/spheres/${id}`,
      {},
      sphere
    );
    return sphereUpdated;
  };

  createSphere = async (sphere: Partial<Sphere>) => {
    const response = await this._callRequest(POST, `/spheres`, {}, sphere);

    // Wait sphere indexation
    let tries = 10;
    while (tries > 0) {
      try {
        const sphere = await this._callRequest(
          GET,
          `/spheres/${response.id}?unicity=${uuidv4()}`
        );
        if (sphere) {
          return sphere;
        }
        tries--;
      } catch (error) {}
      await sleep(1000);
    }
    return response;
  };

  getSpheres = async ({
    max,
    offset,
    searchQuery,
    filters,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
  }): Promise<PaginableList<Sphere>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: "/spheres",
      queryString,
    });
  };

  createGuestAccess = async ({
    name,
    link,
  }: {
    name?: string;
    link: string;
  }): Promise<{ userName: string; password: string }> => {
    const guestAccess = await this._callRequestWithoutToken(
      POST,
      `/users/guests`,
      {},
      {
        name,
        link,
      }
    );
    return guestAccess;
  };

  registerGuest = async (body: { email: string }) => {
    const response = await this._callRequest(
      POST,
      `/users/guests/register`,
      undefined,
      body
    );
    return response;
  };

  finalizeGuestRegistration = async (body: {
    firstName: string;
    lastName?: string;
    validationCode: string;
    acceptPrivatePolicy: boolean;
  }) => {
    const response = await this._callRequest(
      POST,
      `/users/guests/convert`,
      undefined,
      body
    );
    return response;
  };

  getSessions = async ({
    max,
    offset,
    searchQuery,
    filters,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
  }): Promise<PaginableList<Session>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }
    queryString["_sort-field"] = "name";

    return await this._callRequestWithPagination({
      max,
      offset,
      path: "/sessions",
      queryString,
    });
  };

  fetchSessionReport = async ({
    sphereId,
    category,
    body,
  }: {
    sphereId: string;
    category: string;
    body: any;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [
      `sphereId=${sphereId}`,
      `category=${category}`,
    ];
    const reponse = await this._callRequest(
      POST,
      `/sessions/reports?_filters=${filtersQueries.join(";")}`,
      null,
      body
    );
    return reponse;
  };

  getSessionChildrenIds = async ({
    sessionId,
  }: {
    sessionId: string;
  }): Promise<string[]> => {
    const result = await this._callRequestWithPagination<{ id: string }>({
      max: 1000,
      offset: 0,
      path: `/sessions`,
      queryString: {
        _filters: `parentSessionIds=${sessionId}`,
        _fields: "id",
      },
    });
    return result.items.map((session) => session.id);
  };

  getSession = async (id: string): Promise<Session> => {
    return this._callRequest(GET, `/sessions/${id}`);
  };

  saveSession = async (
    id: string,
    session: Partial<SessionPut>
  ): Promise<Session> => {
    const response = await this._callRequest(
      PUT,
      `/sessions/${id}`,
      {},
      session
    );

    return response;
  };

  createSession = async (session: SessionPost) => {
    const response = await this._callRequest(POST, `/sessions`, {}, session);

    // Wait sphere indexation
    let tries = 10;
    while (tries > 0) {
      try {
        const session = await this._callRequest(
          GET,
          `/sessions/${response.id}?unicity=${uuidv4()}`
        );
        if (session) {
          return session;
        }
        tries--;
      } catch (error) {}
      await sleep(1000);
    }
    return response;
  };

  joinSession = async ({
    sessionId,
    accessCode,
  }: {
    sessionId: string;
    accessCode: string;
  }) => {
    const member = await this._callRequest(
      POST,
      `/sessions/${sessionId}/join`,
      {},
      {
        sessionKey: accessCode,
      }
    );

    // Wait sphere indexation
    let tries = 10;
    let session;
    while (tries > 0) {
      try {
        session = await this._callRequest(
          GET,
          `/sessions/${sessionId}?unicity=${uuidv4()}`
        );
        if (session) {
          return { member, session };
        }
        tries--;
      } catch (error) {}
      await sleep(1000);
    }
    return { member };
  };

  getSessionMembers = async ({
    max,
    offset,
    searchQuery,
    filters,
    sessionId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sessionId: string;
    filters?: string[];
  }): Promise<PaginableList<MemberResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/sessions/${sessionId}/members`,
      queryString,
    });
  };

  updateSessionMember = async (
    sessionId: string,
    memberId: string,
    member: SessionMemberPut
  ): Promise<MemberResponse> => {
    return await this._callRequest(
      PUT,
      `/sessions/${sessionId}/members/${memberId}`,
      null,
      member
    );
  };
  addSessionMember = async (
    sessionId: string,
    member: SessionMemberPost
  ): Promise<MemberResponse> => {
    return await this._callRequest(
      POST,
      `/sessions/${sessionId}/members`,
      null,
      member
    );
  };
  deleteSessionMember = async (
    sessionId: string,
    memberId: string
  ): Promise<MemberResponse> => {
    return await this._callRequest(
      DELETE,
      `/sessions/${sessionId}/members/${memberId}`,
      null
    );
  };
  deleteSession = async (sessionId: string): Promise<void> => {
    await this._callRequest(DELETE, `/sessions/${sessionId}`, null);
  };

  getSessionAxesGroups = async ({
    sessionId,
    groupId,
  }: {
    sessionId: string;
    groupId: string;
  }): Promise<SphereAxeResponse[]> => {
    return await this._callRequest(
      GET,
      `/thinkings/definitions/session-axes/${sessionId}_${groupId}`,
      {}
    );
  };

  updateSessionAxes = async ({
    sessionId,
    groupId,
    axes,
  }: {
    sessionId: string;
    groupId: string;
    axes: SphereAxe[];
  }): Promise<SphereAxeResponse[]> => {
    return await this._callRequest(
      PUT,
      `/thinkings/definitions/session-axes/${sessionId}_${groupId}`,
      {},
      axes
    );
  };

  leaveSession = async (sessionId: string, memberId: string) => {
    const member = await this._callRequest(
      DELETE,
      `/sessions/${sessionId}/members/${memberId}`,
      {},
      {}
    );
    return member;
  };

  getSessionThinkingAxes = async (
    sessionId: string,
    groupId: string
  ): Promise<SphereAxeResponse[]> => {
    return this._callRequest(
      GET,
      `/thinkings/definitions/session-axes/${sessionId}_${groupId}`,
      []
    );
  };

  getFormEntitlements = async ({
    max,
    offset,
    searchQuery,
    filters,
    sphereId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    sphereId: string;
    filters?: string[];
  }): Promise<PaginableList<FormEntitlementResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/forms/spheres/${sphereId}/entitlements`,
      queryString,
    });
  };

  createFormEntitlement = async ({
    formId,
    sphereId,
    ...entitlementBody
  }: {
    formId: string;
    sphereId: string;
  } & Partial<FormEntitlementBody>): Promise<FormEntitlementResponse> => {
    return await this._callRequest(
      POST,
      `/forms/spheres/${sphereId}/entitlements`,
      null,
      {
        formId,
        ...entitlementBody,
      }
    );
  };
  updateFormEntitlement = async ({
    formId,
    sphereId,
    ...entitlementBody
  }: {
    formId: string;
    sphereId: string;
  } & Partial<FormEntitlementBody>): Promise<FormEntitlementResponse> => {
    return await this._callRequest(
      PUT,
      `/forms/spheres/${sphereId}/entitlements/${formId}`,
      null,
      {
        ...entitlementBody,
      }
    );
  };
  deleteFormEntitlement = async ({
    formId,
    sphereId,
  }: {
    formId: string;
    sphereId: string;
  }): Promise<FormEntitlementResponse> => {
    return await this._callRequest(
      DELETE,
      `/forms/spheres/${sphereId}/entitlements/${formId}`
    );
  };

  getForms = async ({
    max,
    offset,
    searchQuery,
    filters,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
  }): Promise<PaginableList<LightForm>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/forms/definitions`,
      queryString,
    });
  };
  addSessionToFormEntitlement = async ({
    formId,
    sphereId,
    sessionId,
  }: {
    formId: string;
    sphereId: string;
    sessionId: string;
  }): Promise<FormEntitlementResponse> => {
    return await this._callRequest(
      POST,
      `/forms/spheres/${sphereId}/entitlements/${formId}/sessions`,
      null,
      {
        sessionId,
      }
    );
  };
  deleteSessionFromFormEntitlement = async ({
    formId,
    sphereId,
    sessionId,
  }: {
    formId: string;
    sphereId: string;
    sessionId: string;
  }): Promise<FormEntitlementResponse> => {
    return await this._callRequest(
      DELETE,
      `/forms/spheres/${sphereId}/entitlements/${formId}/sessions/${sessionId}`
    );
  };

  getFormReport = async (body: {
    includeFormIds?: string[];
    onlyFormIds?: string[];
  }): Promise<FormReportResponse> => {
    const formReportResponse = this._callRequest(
      POST,
      `/forms/reports`,
      {},
      body
    );
    return formReportResponse;
  };
  getForm = async (formId: string): Promise<Form> => {
    const form = this._callRequest(GET, `/forms/definitions/${formId}`, {}, {});
    return form;
  };
  getFormReportDefinition = async (
    formId: string
  ): Promise<FormReportDefinition> => {
    const form = this._callRequest(
      GET,
      `/forms/report-definitions/${formId}`,
      {},
      {}
    );
    return form;
  };

  getFormSurveys = async (
    from: Date,
    to: Date,
    filters?: {
      max?: number;
      formId?: string;
      sphereIds?: string[];
      sessionIds?: string[];
    }
  ): Promise<FormSurvey[]> => {
    const filtersQueries: string[] = [];
    if (filters?.sphereIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sphereId=${filters.sphereIds.join(",")}`)
      );
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sessionId=${filters.sessionIds.join(",")}`)
      );
    }
    if (filters?.formId) {
      filtersQueries.push(encodeURIComponent(`formId=${filters.formId}`));
    }

    const surveys = await this._callRequest(
      GET,
      `/forms?_max=${
        filters?.max || MAX_SAFE
      }&_filters=creationDate>=${from.toISOString()};creationDate<=${to.toISOString()};${filtersQueries.join(
        ";"
      )}&_sort-field=creationDate&_sort-dir=desc`
    );
    return surveys;
  };

  getMyForms = async (): Promise<Partial<FormSurvey>[]> => {
    const surveys = await this._callRequest(
      GET,
      `/forms?_groupBy=lastByFormId`
    );
    return surveys;
  };

  getFormSurvey = async (id: string): Promise<FormSurvey> => {
    const survey = await this._callRequest(GET, `/forms/${id}`);
    return survey;
  };

  createFormSurvey = async (form: FormSurveyPost): Promise<FormSurvey> => {
    const survey = await this._callRequest(POST, `/forms`, null, form);
    return survey;
  };
  updateFormSurvey = async (
    id: string,
    form: FormSurveyPut
  ): Promise<FormSurveyPost> => {
    const survey = await this._callRequest(PUT, `/forms/${id}`, null, form);
    return survey;
  };

  getUrlAliases = async ({
    max,
    offset,
    searchQuery,
    sessionId,
    sphereId,
    category,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    category?: string;
    sphereId?: string;
    sessionId?: string;
  } & ({} | {})): Promise<PaginableList<UrlAlias>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    const filters = [`category=${category}`];
    if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
      filters.push(`linkTo=sphere`);
    }
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
      filters.push(`linkTo=session`);
    }
    queryString._filters = filters.join(";");
    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/shorten-urls`,
      queryString,
    });
  };

  getUrlAlias = async (id: string): Promise<UrlAlias | undefined> => {
    const response = await this._callRequestWithPagination({
      max: 1,
      offset: 0,
      path: `/shorten-urls`,
      queryString: { _filters: `id=${id}` },
    });

    if (response && response.items && response.items.length > 0) {
      return response.items[0] as UrlAlias;
    }
    return undefined;
  };

  getUrlCampaign = async (
    id: string,
    validationCode: string
  ): Promise<UrlCampaignResponse> => {
    const user = await Auth.currentUserInfo();
    let token: string | undefined = undefined;
    if (user) {
      const {
        accessToken: { jwtToken },
      } = (await Auth.currentSession()) as any;
      token = jwtToken;
    }

    try {
      const response = await API.get(
        "seenapps",
        `/shorten-urls/${id}/campaign?validationCode=${encodeURIComponent(
          validationCode
        )}`,
        {
          headers: {
            Authorization: token,
            Source: SOURCE,
            "Accept-Language": this.language,
            "Enable-Super-Admin": isSuperAdminModeEnabled()
              ? "true"
              : undefined,
          },
          response: true,
        }
      );

      return response.data;
    } catch (error) {
      throw new Error("Campaign Forbidden");
    }
  };

  createUrlAlias = async (body: UrlCreationInput): Promise<UrlAlias> => {
    return await this._callRequest(POST, `/shorten-urls`, {}, body);
  };

  updateUrlAlias = async ({
    id,
    ...body
  }: { id: string } & Partial<UrlUpdateInput>): Promise<UrlAlias> => {
    return await this._callRequest(PUT, `/shorten-urls/${id}`, {}, body);
  };

  deleteUrlAlias = async (id: string): Promise<void> => {
    await this._callRequest(DELETE, `/shorten-urls/${id}`);
  };

  getStrategies = async ({
    sphereId,
    sessionId,
    searchQuery,
    max,
    offset,
    linkTo,
  }: {
    max?: number;
    offset?: number;
    userId?: string;
    sphereId?: string;
    sessionId?: string;
    linkTo: "session" | "sphere" | "user";
    searchQuery?: string;
  }): Promise<PaginableList<Strategy>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    const filters = [`linkTo=${linkTo}`];
    if (sessionId) {
      filters.push(`sessionId=${sessionId}`);
    } else if (sphereId) {
      filters.push(`sphereId=${sphereId}`);
    }
    queryString._filters = filters.join(";");
    return await this._callRequestWithPagination<Strategy>({
      max: max || 200,
      offset: offset || 0,
      path: `/strategies`,
      queryString,
    });
  };

  deleteStrategy = async (id: string): Promise<void> => {
    await this._callRequest(DELETE, `/strategies/${id}`);
  };

  addStrategy = async (strategyBody: StrategyPost): Promise<Strategy> => {
    return await this._callRequest(POST, `/strategies`, null, strategyBody);
  };

  updateStrategy = async (
    id: string,
    strategyBody: Partial<StrategyPut>
  ): Promise<Strategy> => {
    return await this._callRequest(
      PUT,
      `/strategies/${id}`,
      null,
      strategyBody
    );
  };

  fetchReport = async ({
    sessionId,
    sphereId,
    category,
    body,
    period,
    tagIds,
    feelingType,
    onlyDirect,
    sessionLevel,
  }: {
    sessionId?: string;
    sphereId?: string;
    category?: feelingCategory | feelingCategory[];
    body: any;
    period: FilterDate;
    tagIds: string[];
    feelingType: FeelingType;
    onlyDirect?: boolean;
    sessionLevel?: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];

    const timePeriod = feelingType === "feeling" ? "day" : feelingType.type;
    if (period.startDate) {
      filtersQueries.push(
        encodeURIComponent(
          `timestamp>=${moment(period.startDate)
            .startOf(timePeriod)
            .toISOString()}`
        )
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        encodeURIComponent(
          `timestamp<=${moment(period.endDate).endOf(timePeriod).toISOString()}`
        )
      );
    }

    if (tagIds?.length) {
      filtersQueries.push(encodeURIComponent(`tagIds=${tagIds.join(",")}`));
    }
    if (feelingType === "feeling") {
      filtersQueries.push(`isAnEvaluation=false`);
    } else {
      filtersQueries.push(`isAnEvaluation=true`);
      filtersQueries.push(`lastEvaluationOfThePeriod=true`);
      filtersQueries.push(`evaluationPeriodType=${feelingType.type}`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const directLinkTo: Module = sessionId
      ? "session"
      : sphereId
      ? "sphere"
      : "myself";
    if (sessionId || onlyDirect) {
      filtersQueries.push(encodeURIComponent(`directLinkTo=${directLinkTo}`));
    }
    if (sessionId && onlyDirect) {
      filtersQueries.push(`sessionId=${sessionId}`);
    } else if (sessionId) {
      const childrenIds = await api.getSessionChildrenIds({ sessionId });
      filtersQueries.push(`sessionId=${[sessionId, ...childrenIds].join(",")}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (category) {
      filtersQueries.push(
        `category=${Array.isArray(category) ? category.join(",") : category}`
      );
    }

    const reponse =
      sphereId || sessionId
        ? await this._callRequest(
            POST,
            `/feelings/sphere-reports?_filters=${filtersQueries.join(";")}`,
            null,
            body
          )
        : await this._callRequest(
            POST,
            `/feelings/myself-reports?_filters=${filtersQueries.join(";")}`,
            null,
            body
          );
    return reponse;
  };

  fetchThinkingReport = async ({
    sessionId,
    sphereId,
    category,
    body,
    period,
    tagIds,
    onlyChildren,
    sessionLevel,
  }: {
    sessionId?: string;
    sphereId: string;
    category: feelingCategory;
    body: any;
    period: FilterDate;
    onlyChildren?: boolean;
    tagIds?: string[];
    sessionLevel?: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];
    if (period.startDate) {
      filtersQueries.push(
        encodeURIComponent(
          `timestamp>=${moment(period.startDate).startOf("day").toISOString()}`
        )
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        encodeURIComponent(
          `timestamp<=${moment(period.endDate).endOf("day").toISOString()}`
        )
      );
    }

    const directLinkTo: Module =
      onlyChildren || sessionId ? "session" : "sphere";
    filtersQueries.push(encodeURIComponent(`linkTo=${directLinkTo}`));
    if (onlyChildren) {
      filtersQueries.push(encodeURIComponent(`onlyChildren=true`));
    }
    if (tagIds?.length) {
      filtersQueries.push(encodeURIComponent(`tagIds=${tagIds.join(",")}`));
    }
    if (sessionId) {
      filtersQueries.push(`sessionId=${sessionId}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (category) {
      filtersQueries.push(`category=${category}`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const report = await this._callRequest(
      POST,
      `/thinkings/sphere-reports?_filters=${filtersQueries.join(";")}`,
      null,
      body
    );

    return report;
  };

  fetchFormReport = async ({
    formId,
    sessionId,
    sphereId,
    body,
    period,
    tagIds,
    onlyDirect,
    onlyLastSurvey,
    sessionLevel,
  }: {
    formId: string;
    sessionId?: string;
    sphereId?: string;
    category?: feelingCategory;
    body: any;
    period: FilterDate;
    tagIds: string[];
    onlyDirect?: boolean;
    onlyLastSurvey: boolean;
    sessionLevel: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [`formId=${formId}`];
    const timePeriod = "day";
    if (period.startDate) {
      filtersQueries.push(
        encodeURIComponent(
          `creationDate>=${moment(period.startDate)
            .startOf(timePeriod)
            .toISOString()}`
        )
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        encodeURIComponent(
          `creationDate<=${moment(period.endDate)
            .endOf(timePeriod)
            .toISOString()}`
        )
      );
    }

    if (tagIds?.length) {
      filtersQueries.push(encodeURIComponent(`tagIds=${tagIds.join(",")}`));
    }
    if (onlyLastSurvey) {
      filtersQueries.push(`only-last-survey=true`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const directLinkTo: Module = sessionId
      ? "session"
      : sphereId
      ? "sphere"
      : "myself";
    if (sessionId || onlyDirect) {
      filtersQueries.push(encodeURIComponent(`directLinkTo=${directLinkTo}`));
    }
    if (sessionId && onlyDirect) {
      filtersQueries.push(`sessionId=${sessionId}`);
    } else if (sessionId) {
      const childrenIds = await api.getSessionChildrenIds({ sessionId });
      filtersQueries.push(`sessionId=${[sessionId, ...childrenIds].join(",")}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    const reponse =
      sphereId || sessionId
        ? await this._callRequest(
            POST,
            `/forms/sphere-reports?_filters=${filtersQueries.join(";")}`,
            null,
            body
          )
        : await this._callRequest(
            POST,
            `/forms/myself-reports?_filters=${filtersQueries.join(";")}`,
            null,
            body
          );
    return reponse;
  };
  translate = async (
    request: TranslationRequest
  ): Promise<TranslationResponse> => {
    const reponse = await this._callRequest(
      POST,
      `/translations`,
      null,
      request
    );
    return reponse;
  };

  createChatThread = async (
    mode: string,
    text: string,
    userName?: string,
    feelingContext?: FeelingContext
  ): Promise<ChatResponse> => {
    return await this._callRequest(POST, `/ai/chat-threads`, null, {
      mode,
      text,
      userName,
      feelingContext,
    });
  };
  sendMessageToChatThread = async (
    id: string,
    text: string
  ): Promise<ChatResponse> => {
    return await this._callRequest(POST, `/ai/chat-threads/${id}`, null, {
      text,
    });
  };

  getGlobalMembers = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<MemberResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }
    queryString._filters = `${queryString._filters}${
      queryString._filters ? ";" : ""
    }sphereId=*`;
    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/members`,
      queryString,
    });
  };

  getGlobalMember = async (memberId: string): Promise<MemberResponse> => {
    return await this._callRequest(GET, `/members/${memberId}`);
  };

  addGlobalMember = async (member: PostMember): Promise<MemberResponse[]> => {
    return await this._callRequest(POST, `/members`, null, {
      ...member,
      sphereId: "*",
    });
  };

  updateGlobalMember = async (
    memberId: string,
    member: PostMember
  ): Promise<MemberResponse> => {
    return await this._callRequest(PUT, `/members/${memberId}`, null, member);
  };

  getSubscriptionReport = async ({
    callbackUrl,
  }: {
    callbackUrl: string;
  }): Promise<SubscriptionReport> => {
    return this._callRequest(
      POST,
      `/users/subscriptions/report`,
      {},
      {
        callbackUrl,
      }
    );
  };

  getPromotionCodes = async ({
    filters,
  }: {
    filters?: string[];
  }): Promise<PromotionCode[]> => {
    return this._callRequest(
      GET,
      `/users/subscriptions/promotion-codes?_filters=${
        filters?.join(";") || ""
      }`,
      {}
    );
  };

  addPromotionCode = async (
    promotionCode: PromotionCodePost
  ): Promise<PromotionCode> => {
    return await this._callRequest(
      POST,
      `/users/subscriptions/promotion-codes`,
      null,
      promotionCode
    );
  };

  getSphereSubscriptionReport = async ({
    sphereId,
    callbackUrl,
    requestedBy,
  }: {
    sphereId: string;
    callbackUrl: string;
    requestedBy: { firstName: string; lastName: string; email: string };
  }): Promise<SphereSubscriptionReport> => {
    return this._callRequest(
      POST,
      `/spheres/${sphereId}/subscriptions/report`,
      {},
      {
        callbackUrl,
        requestedBy,
      }
    );
  };
  updateSphereSubscriptionLicense = async ({
    sphereId,
    subscription,
    quantity,
  }: {
    sphereId: string;
    subscription: Subscription;
    quantity: number;
  }) => {
    return this._callRequest(
      PUT,
      `/spheres/${sphereId}/subscriptions/${subscription}/license`,
      {},
      {
        quantity,
      }
    );
  };

  getStrategyAssessments = async (
    from: Date,
    to: Date,
    filters?: {
      max?: number;
      formId?: string;
      sphereIds?: string[];
      sessionIds?: string[];
    }
  ): Promise<StrategyAssessment[]> => {
    const filtersQueries: string[] = [];
    if (filters?.sphereIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sphereId=${filters.sphereIds.join(",")}`)
      );
    }
    if (filters?.sessionIds?.length) {
      filtersQueries.push(
        encodeURIComponent(`sessionId=${filters.sessionIds.join(",")}`)
      );
    }

    const assessments = await this._callRequest(
      GET,
      `/strategies/assessments?_max=${
        filters?.max || MAX_SAFE
      }&_filters=creationDate>=${from.toISOString()};creationDate<=${to.toISOString()};${filtersQueries.join(
        ";"
      )}&_sort-field=creationDate&_sort-dir=desc`
    );
    return assessments;
  };
  getStrategyAssessment = async (
    assessmentId: string
  ): Promise<StrategyAssessment> => {
    const assessment = await this._callRequest(
      GET,
      `/strategies/assessments/${assessmentId}`
    );
    return assessment;
  };

  addStrategyAssessment = async (assessment: StrategyAssessmentPost) => {
    const response = await this._callRequest(
      POST,
      `/strategies/assessments`,
      {},
      assessment
    );
    return response;
  };
  updateStrategyAssessment = async (
    assessmentId: string,
    assessment: StrategyAssessmentPut
  ) => {
    const response = await this._callRequest(
      PUT,
      `/strategies/assessments/${assessmentId}`,
      {},
      assessment
    );
    return response;
  };

  fetchAssessmentReport = async ({
    sessionId,
    sphereId,
    body,
    period,
    tagIds,
    onlyChildren,
    sessionLevel,
  }: {
    sessionId?: string;
    sphereId: string;
    body: any;
    period: FilterDate;
    onlyChildren?: boolean;
    tagIds?: string[];
    sessionLevel?: number;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];
    if (period.startDate) {
      filtersQueries.push(
        encodeURIComponent(
          `updateDate>=${moment(period.startDate).startOf("day").toISOString()}`
        )
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        encodeURIComponent(
          `updateDate<=${moment(period.endDate).endOf("day").toISOString()}`
        )
      );
    }

    const directLinkTo: Module =
      onlyChildren || sessionId ? "session" : "sphere";
    filtersQueries.push(
      encodeURIComponent(`onlyChildren=${onlyChildren || false}`)
    );
    filtersQueries.push(encodeURIComponent(`directLinkTo=${directLinkTo}`));
    if (tagIds?.length) {
      filtersQueries.push(encodeURIComponent(`tagIds=${tagIds.join(",")}`));
    }
    if (sessionId) {
      filtersQueries.push(`sessionId=${sessionId}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (sessionLevel) {
      filtersQueries.push(`session-level=${sessionLevel}`);
    }

    const report = await this._callRequest(
      POST,
      `/strategies/assessments/sphere-reports?_filters=${filtersQueries.join(
        ";"
      )}`,
      null,
      body
    );

    return report;
  };

  fetchMyAssessmentReport = async ({
    body,
    period,
  }: {
    body: any;
    period: FilterDate;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [];
    if (period.startDate) {
      filtersQueries.push(
        encodeURIComponent(
          `updateDate>=${moment(period.startDate).startOf("day").toISOString()}`
        )
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        encodeURIComponent(
          `updateDate<=${moment(period.endDate).endOf("day").toISOString()}`
        )
      );
    }
    const report = await this._callRequest(
      POST,
      `/strategies/assessments/myself-reports?_filters=${filtersQueries.join(
        ";"
      )}`,
      null,
      body
    );

    return report;
  };
  signIn = async (body: { email: string }): Promise<void> => {
    await this._callRequestWithoutToken(POST, "/users/sign-in", null, body);
  };

  getCampaigns = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<CampaignResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/campaigns/definitions`,
      queryString,
    });
  };
  createCampaign = async (
    campaign: CampaignPost
  ): Promise<CampaignResponse> => {
    return await this._callRequest(
      POST,
      `/campaigns/definitions`,
      null,
      campaign
    );
  };
  updateCampaign = async (
    id: string,
    campaign: Partial<CampaignPut>
  ): Promise<CampaignResponse> => {
    return await this._callRequest(
      PUT,
      `/campaigns/definitions/${id}`,
      null,
      campaign
    );
  };
  getCampaign = async (campaignId: string): Promise<CampaignResponse> => {
    return await this._callRequest(
      GET,
      `/campaigns/definitions/${campaignId}`,
      null
    );
  };

  getCampaignCycles = async ({
    campaignId,
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    campaignId: string;
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<Cycle>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/campaigns/definitions/${campaignId}/cycles`,
      queryString,
    });
  };

  createCampaignCycle = async ({
    campaignId,
    cycle,
  }: {
    campaignId: string;
    cycle: Partial<CyclePut>;
  }): Promise<Cycle> => {
    return await this._callRequest(
      POST,
      `/campaigns/definitions/${campaignId}/cycles`,
      null,
      cycle
    );
  };
  updateCampaignCycle = async ({
    id,
    campaignId,
    cycle,
  }: {
    id: number;
    campaignId: string;
    cycle: Partial<CyclePut>;
  }): Promise<Cycle> => {
    return await this._callRequest(
      PUT,
      `/campaigns/definitions/${campaignId}/cycles/${id}`,
      null,
      cycle
    );
  };

  getActiveParticipations = async (): Promise<ParticipationResponse[]> => {
    const filtersQueries: string[] = [];
    filtersQueries.push(encodeURIComponent(`systemStatus=active`));
    filtersQueries.push(encodeURIComponent(`status=blank,inProgress`));

    const participations = await this._callRequest(
      GET,
      `/campaigns/participations?_max=${MAX_SAFE}&_filters=${filtersQueries.join(
        ";"
      )}&_sort-field=updateDate&_sort-dir=desc`
    );
    return participations;
  };
  updateParticipation = async ({
    id,
    participation,
  }: {
    id: string;
    participation: ParticipationPut;
  }): Promise<ParticipationResponse> => {
    return await this._callRequest(
      PUT,
      `/campaigns/participations/${id}`,
      null,
      participation
    );
  };
  getParticipationFromCampaignId = async (
    campaignId: string
  ): Promise<ParticipationResponse> => {
    return await this._callRequest(
      POST,
      `/campaigns/participations`,
      undefined,
      {
        campaignId,
      }
    );
  };
  getParticipationFacets = async ({
    campaignId,
    cycleId,
  }: {
    campaignId: string;
    cycleId?: number;
  }): Promise<FacetResponse> => {
    let queryString: string[] = [];
    const filters = [`campaignId=${campaignId}`];
    if (cycleId) {
      filters.push(`cycleId=${cycleId}`);
    }
    if (filters?.length) {
      queryString.push(`_filters=${filters.join(";")}`);
    }

    return await this._callRequest(
      GET,
      `/campaigns/participations/facets?${
        queryString.length ? queryString.join("&") : ""
      }`
    );
  };

  fetchCampaignReport = async ({
    subject,
    sessionId,
    sphereId,
    campaignId,
    body,
    filters,
    period,
  }: {
    subject: Subject.CampaignParticipation;
    campaignId?: string;
    sessionId?: string;
    sphereId?: string;
    body: any;
    filters: string[];
    period: FilterDate;
  }): Promise<ReportResponse> => {
    const filtersQueries: string[] = [...filters];
    if (sessionId) {
      filtersQueries.push(`sessionId=${sessionId}`);
    } else if (sessionId) {
      const childrenIds = await api.getSessionChildrenIds({ sessionId });
      filtersQueries.push(`sessionId=${[sessionId, ...childrenIds].join(",")}`);
    }
    if (sphereId) {
      filtersQueries.push(`sphereId=${sphereId}`);
    }
    if (campaignId) {
      filtersQueries.push(`campaignId=${campaignId}`);
    }

    if (period.startDate) {
      filtersQueries.push(
        encodeURIComponent(
          `updateDate>=${moment(period.startDate).startOf("day").toISOString()}`
        )
      );
    }
    if (period.endDate) {
      filtersQueries.push(
        encodeURIComponent(
          `updateDate<=${moment(period.endDate).endOf("day").toISOString()}`
        )
      );
    }

    const reponse = await this._callRequest(
      POST,
      `/campaigns/sphere-reports?_filters=${filtersQueries.join(";")}`,
      null,
      { ...body, subject }
    );
    return reponse;
  };

  getReports = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<CustomReportResponse>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return await this._callRequestWithPagination({
      max,
      offset,
      path: `/reports`,
      queryString,
    });
  };
  getReport = async (id: string): Promise<CustomReportResponse> => {
    return this._callRequest(GET, `/reports/${id}`);
  };
  createReport = async (report: ReportPost): Promise<CustomReportResponse> => {
    return await this._callRequest(POST, `/reports`, null, report);
  };
  updateReport = async (
    id: string,
    report: Partial<ReportPut>
  ): Promise<CustomReportResponse> => {
    return await this._callRequest(PUT, `/reports/${id}`, null, report);
  };
  deleteReport = async (id: string): Promise<void> => {
    await this._callRequest(DELETE, `/reports/${id}`, null);
  };
  getReportCards = async ({
    showHidden,
    reportId,
    reportPartId,
  }: {
    reportId: string;
    reportPartId: string;
    showHidden: boolean;
  }): Promise<CardResponse[]> => {
    const filters = [`reportPartId=${reportPartId}`];
    if (!showHidden) {
      filters.push(`hidden=false`);
    }

    const cards = await this._callRequestWithPagination<CardResponse>({
      max: 1000,
      offset: 0,
      path: `/reports/${reportId}/cards`,
      queryString: {
        _filters: filters.join(";"),
      },
    });
    return cards.items;
  };
  updateCardPrompt = async ({
    cardId,
    reportId,
    prompt,
  }: {
    reportId: string;
    cardId: string;
    prompt: string;
  }): Promise<CardResponse[]> => {
    const cards = await this._callRequest(
      PUT,
      `/reports/${reportId}/cards/${cardId}/prompt`,
      null,
      { prompt }
    );
    return cards.items;
  };

  getReportRenders = async ({
    max,
    offset,
    searchQuery,
    filters,
    sort,
    reportId,
  }: {
    max: number;
    offset: number;
    searchQuery?: string;
    reportId: string;
    filters?: string[];
    sort?: { field: string; direction: "asc" | "desc" };
  }): Promise<PaginableList<ReportRenders>> => {
    const queryString: any = {};
    if (searchQuery) {
      queryString.q = searchQuery;
    }
    if (filters) {
      queryString._filters = filters.join(";");
    }

    if (sort) {
      queryString["_sort-field"] = sort.field;
      queryString["_sort-dir"] = sort.direction;
    }

    return this._callRequestWithPagination({
      max,
      offset,
      path: `/reports/${reportId}/renders`,
      queryString,
    });
  };
  renderReport = async (
    reportId: string,
    {
      name,
      format,
      lang,
      partsIds,
    }: {
      name: string;
      format: "pdf" | "docx";
      lang: string;
      partsIds: string[];
    }
  ): Promise<void> => {
    await this._callRequest(POST, `/reports/${reportId}/renders`, null, {
      name,
      format,
      lang,
      partsIds,
    });
  };
  deleteReportRender = async ({
    reportId,
    renderId,
  }: {
    reportId: string;
    renderId: string;
  }) => {
    await this._callRequest(DELETE, `/reports/${reportId}/renders/${renderId}`);
  };
}

const api = new Api();

export default api;
